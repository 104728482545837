<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col lg="3">
            <b-form-group label-for="articleCat">
              <template #label>Category<span class="text-danger mr5">*</span></template>
              <v-select
                id="articleCat" 
                placeholder="Select Category"
                v-model="row.mp_category"
                :options="mrCategory"
                :reduce="v => v.value"
              />
              <VValidate 
                name="Category" 
                v-model="row.mp_category" 
                :rules="mrValidation.mp_category" 
              />
            </b-form-group>
          </b-col>

          <div class="w-100"></div>

          <b-col md=4>
            <b-form-group>
              <label>Title (ID)<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mp_title_id" placeholder="e.g Gear Counter"></b-form-input>                  
              <VValidate 
                name="Title (ID)" 
                v-model="row.mp_title_id" 
                :rules="mrValidation.mp_title_id" 
              />
            </b-form-group>
          </b-col>
          <b-col md=4>
            <b-form-group>
              <label>Title (EN)<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mp_title_en" placeholder="e.g Gear Counter"></b-form-input>                  
              <VValidate 
                name="Title (EN)" 
                v-model="row.mp_title_en" 
                :rules="mrValidation.mp_title_en" 
              />
            </b-form-group>
          </b-col>
          <b-col md=4>
            <b-form-group>
              <label>Title (JP)<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mp_title_jp" placeholder="e.g ギアカウンター"></b-form-input>                  
              <VValidate 
                name="Title (JP)" 
                v-model="row.mp_title_jp" 
                :rules="mrValidation.mp_title_jp" 
              />
            </b-form-group>
          </b-col>

          <div class="w-100"></div>
          <b-col lg="4">
            <b-form-group>
              <label>Description (ID)<span class="text-danger" v-if="mrValidation.mp_desc_id && mrValidation.mp_desc_id.required">*</span></label>
              <b-form-textarea v-model="row.mp_desc_id"></b-form-textarea>                  
              <VValidate 
                name="Description (ID)" 
                v-model="row.mp_desc_id" 
                :rules="mrValidation.mp_desc_id" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <label>Description (EN)<span class="text-danger" v-if="mrValidation.mp_desc_en && mrValidation.mp_desc_en.required">*</span></label>
              <b-form-textarea v-model="row.mp_desc_en"></b-form-textarea>                  
              <VValidate 
                name="Description (EN)" 
                v-model="row.mp_desc_en" 
                :rules="mrValidation.mp_desc_en" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <label>Description (JP)<span class="text-danger" v-if="mrValidation.mp_desc_jp && mrValidation.mp_desc_jp.required">*</span></label>
              <b-form-textarea v-model="row.mp_desc_jp"></b-form-textarea>                  
              <VValidate 
                name="Description (JP)" 
                v-model="row.mp_desc_jp" 
                :rules="mrValidation.mp_desc_jp" 
              />
            </b-form-group>
          </b-col>
          <b-col md=12 class="p-4 border">
            <b-row>
              <b-col lg="3" md="4" class="slider-item" v-for="(image, index) in row.mp_image" :key="index">
                <div class="card-img">
                  <b-img fluid class="card-img__media" :src="$parent.uploader(image)" :blank="!image" blank-color="#ccc"/>
                  <div class="bullet-cta">
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      class="btn-icon"
                      triggers="hover"
                      v-b-tooltip.hover="'Update'"
                      @click="editImage(index)"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Delete'"
                      @click="deleteImage(index)"
                    >
                      <i class="far fa-trash-alt" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col lg="3" md="4">
                <a href="javascript:;" class="card-img-adder" @click="addImage">
                  <i class="fas fa-plus"></i>
                  <span>Add Image</span>
                </a>
              </b-col>
            </b-row>
          </b-col>

          <div class="w-100"></div>

          <b-col lg="6">
            <b-form-group class="mt-3">
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row[$parent.statusKey]"
              />
              <VValidate 
                name="Status" 
                v-model="row.mp_is_active" 
                :rules="mrValidation.mp_is_active" 
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="primary" class="btn-rounded" @click="$parent.doSubmitCRUD('VForm', $refs)">Save Changes</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
    <b-modal id="modalImage" title="Form Image" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormImage"
      >
        <b-form @submit.prevent="handleSubmit(submitImage)">
          <b-card-body>
            <b-row>
              <b-col md=12>
                <div class="file-uploader">
                  <label>Image <span class="text-danger mr5">*</span></label>
                  <Uploader v-model="dataModal.image" type="product"/>
                  <VValidate 
                    name="Image" 
                    v-model="dataModal.image" 
                    :rules="{required:1}" 
                  />
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="submitImage" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</b-card>
</template>

<script>

const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mrCategory:Array,
  },
  data(){
    return {
      dataModal: {}
    }
  },
  methods:{
    doSubmitForm(){
      this.$emit('click:doSubmit', 'VForm', this.$refs)
    },
    addImage(){
      this.dataModal = { type: 'add' }
      this.$bvModal.show('modalImage')
    },
    editImage(index){
      this.dataModal = {
        image: _.clone(this.row.mp_image[index]),
        type: 'edit',
        index: index
      }
      this.$bvModal.show('modalImage')
    },
    deleteImage(index){
      this.row.mp_image.splice(index, 1)
    },
    submitImage(){
      this.$refs.VFormImage.validate().then(success => {
        if(!success) return

        if(!this.row.mp_image) this.row.mp_image = []
        if(this.dataModal.type == 'add'){
          this.row.mp_image.push(this.dataModal.image)
        }else{
          this.$set(this.row.mp_image, this.dataModal.index, this.dataModal.image)
        }
        this.$bvModal.hide('modalImage')
      })
    },
  }
}
</script>